html {
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  color: #fff;
  font-family: "Sora-Regular";
  scroll-behavior: smooth;
}

body {
  min-width: 100vw;
  max-width: 100vw;
  overflow-x: hidden;
  background-color: #0e0a31;
  position: relative;
  overflow-y: auto;
  padding-right: 0.2rem;
}

#root {
  display: contents;
}

.hero {
  margin-top: 100px;
  width: 100%;
  max-height: calc(100vh - 100px);
  min-height: calc(100vh - 100px);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-inline: 2rem;
  gap: 1rem;
}

.hero img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.hero h1 {
  font-size: 3rem;
}

.hero p {
  font-size: 2rem;
}

.services-container {
  max-width: 100%;
  display: flex;
  overflow: auto;
  overflow-y: hidden;
  gap: 1rem;
  scroll-snap-type: x mandatory;
  border-radius: 5px;
}

.service-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 2rem;
  width: 100vw;
  gap: 2rem;
  min-height: 100vh;
  justify-content: center;
  min-height: auto;
}

.service-wrapper > h1 {
  font-size: 1.8rem;
  color: #fff;
}

.service-slider-wrapper {
  overflow: hidden;
  position: relative;
  width: 100%;
}

.service-slider-wrapper .left-btn,
.right-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: #000000ab;
  outline: none;
  border: none;
  border-radius: 50%;
  padding: 1rem;
  z-index: 5;
  display: grid;
  place-items: center;
}

.service-slider-wrapper .right-btn {
  right: 20px;
}

.service-slider-wrapper .left-btn {
  left: 20px;
}

.services-container::-webkit-scrollbar {
  height: 0px;
}

.what-we-think {
  width: 100%;
  display: flex;
  padding: 2rem;
  height: 100vh;
  justify-content: center;
  align-items: center;
  gap: 6rem;
}

.what-we-think .image-container {
  width: 400px;
  position: relative;
  box-shadow: 20px -20px 0px 5px var(--primary-color);
}

.what-we-think .image-container img {
  width: 100%;
}

.what-we-think .content {
  width: 400px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.what-we-think .content h1 {
  font-size: 1.8rem;
}

.what-we-think .content p {
  line-height: 120%;
  text-align: left;
}

.who-are-we {
  height: 100vh;
  display: flex;
  gap: 2rem;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: fit-content;
  margin: 0 auto;
}

.who-are-we::after {
  content: "";
  height: 400px;
  aspect-ratio: 1;
  background-color: var(--primary-color);
  position: absolute;
  left: -80px;
  z-index: -1;
  border-radius: 100%;
}

.who-are-we p {
  width: 500px;
  line-height: 120%;
  text-align: center;
}

.who-are-we h1 {
  font-size: 2rem;
}

footer {
  margin-top: 4rem;
  width: 100%;
  display: flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 2rem;
  background-color: #0a0538;
  box-shadow: 10px -6px 5px -7px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 10px -6px 5px -7px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px -6px 5px -7px rgba(0, 0, 0, 0.75);
}

footer img {
  width: 100px;
}

footer p {
  line-height: 140%;
  font-size: 0.8rem;
}

footer a {
  text-decoration: none;
}

footer .social-networks {
  display: flex;
  width: 150px;
  justify-content: space-around;
}

.service-card.card-img {
  min-height: 300px;
  width: 300px;
  border: none;
  padding: 0;
  overflow: hidden;
  border-radius: 5px;
  min-width: 300px;
  max-height: 300px;
}

.service-card.card-img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  overflow: hidden;
}

.service-card.card-img button {
  display: none;
}

.service-card.card-img.pop-up {
  position: fixed;
  min-height: 100vh;
  min-width: 100vw;
  max-height: 100vh;
  max-width: 100vw;
  inset: 0;
  z-index: 100;
  background: #000000d1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.service-card.card-img.pop-up img {
  height: min(500px, 100vw);
  width: auto;
}

.service-card.card-img.pop-up button {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: #000;
  height: 50px;
  width: 50px;
  display: grid;
  place-items: center;
  justify-content: center;
  border-radius: 50%;
}

.section-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  overflow-x: auto;
  height: auto;
  padding: 1rem;
}

.section-wrapper::-webkit-scrollbar {
  height: 0px;
}

.section-wrapper div {
  padding: 1rem;
  outline: 1px solid #4367ff;
  cursor: pointer;
  position: relative;
  white-space: nowrap;
}

.section-wrapper div.active {
  background-color: #4367ff;
}

.section-wrapper div.active::after {
  display: block;
}

.section-wrapper div::after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #4367ff;
  transform: rotate(45deg) translateX(-50%);
  bottom: -16px;
  left: 50%;
  display: none;
}

.whatsapp_widget_icon {
  border: none !important;
}

@media screen and (max-width: 1000px) {
  .what-we-think {
    gap: 2rem;
  }
}

@media screen and (max-width: 760px) {
  .section-wrapper {
    justify-content: flex-start;
  }

  .collapse-content-block h1 {
    text-align: left !important;
    margin-right: auto;
  }

  .collapse-content-block p {
    text-align: left !important;
  }

  #get-in-touch .form-wrapper {
    width: 350px !important;
  }

  .service-wrapper {
    padding: 4rem 0.5rem;
    min-height: 50vh;
    height: auto;
  }

  .service-wrapper.image-slider-wrapper {
    padding: 4rem 0.5rem;
    height: auto;
  }

  nav {
    padding-inline: 1rem !important;
  }

  nav ul,
  .myspace-btn {
    display: none !important;
  }

  nav .mobile-menu-btn {
    display: block;
  }

  .service-slider-wrapper .right-btn,
  .service-slider-wrapper .left-btn {
    display: none;
  }

  .what-we-think {
    position: relative;
    width: 100%;
    height: 50vh;
  }

  .what-we-think .image-container {
    position: absolute;
    width: calc(100% - 2rem);
    z-index: -1;
  }

  .what-we-think .image-container img {
    filter: brightness(0.3);
  }

  .who-are-we {
    height: 50vh;
    padding-block: 2rem;
  }

  .who-are-we::after {
    display: none;
  }

  .who-are-we p {
    width: 100%;
    padding-inline: 1rem;
    line-height: 120%;
    text-align: justify;
  }

  .who-are-we h1 {
    font-size: 2rem;
  }

  .what-we-think .image-container {
    box-shadow: 8px -8px 0px 2px var(--primary-color);
  }

  footer {
    flex-wrap: wrap;
    gap: 1rem;
    padding: 2rem;
  }

  footer .social-networks {
    width: 100%;
  }

  .hero p {
    font-size: 1rem;
  }

  .hero h1 {
    font-size: 2.5rem;
  }

  .hero {
    align-items: flex-start;
  }

  .collapse-content-block h1 {
    text-align: center;
  }

  #get-in-touch {
    flex-direction: column;
    gap: 2.5rem !important;
    height: auto !important;
    padding-inline: 1rem !important;
  }

  #get-in-touch h1 {
    text-align: center;
  }

  #get-in-touch p {
    text-align: justify !important;
  }

  #get-in-touch .contact-social-networks {
    display: none !important;
  }

  .desktop-footer {
    display: none !important;
  }

  footer .mobile-footer {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  footer p {
    text-align: center;
    margin-bottom: 2rem;
  }
}

nav.open {
  height: 100% !important;
  flex-direction: column;
  padding: 3rem;
  overflow: hidden;
  touch-action: none;
}

nav.open ul {
  display: flex !important;
}

nav.open .myspace-btn {
  display: block !important;
  margin-block: 40px;
}

nav.open ul {
  flex-direction: column;
  align-items: center;
  gap: 2rem !important;
  font-size: 1.5rem;
}

nav.open .mobile-menu-btn {
  display: none;
}

nav.open .mobile-menu-btn-close {
  position: absolute;
  top: 20px;
  right: 10px;
  display: block;
}

.mobile-menu-btn,
.mobile-menu-btn-close {
  background: transparent;
  outline: none;
  border: none;
  display: none;
}

.collapse-css-transition {
  transition: height 150ms cubic-bezier(0.4, 0, 0.2, 1);
  display: flex !important;
  justify-content: center !important;
}

.collapse-content-block {
  display: flex;
  width: 700px;
  color: #000;
  margin: 30px 0px;
  padding: 2rem;
  position: relative;
  flex-direction: column;
  align-items: center;
  background-color: var(--primary-color);
  overflow: hidden;
}

.collapse-content-block h1 {
  color: #fff;
  padding: 1rem 0rem;
  font-size: 2rem;
}

.collapse-content-block p {
  color: #fff;
  margin-top: 10px;
  text-align: center;
}

.collapse-content-block svg {
  position: absolute;
  top: 10px;
  right: 10px;
}

.collapse-content-block svg:hover {
  cursor: pointer;
}

.collapse-content-block svg > path {
  fill: #fff;
}

.collapse-content-block span {
  color: #fff;
}

.collapse-content-block ul {
  padding-top: 1rem;
}

.collapse-content-block ul li {
  color: #fff !important;
  line-height: 2rem !important;
  text-align: left;
}

#get-in-touch {
  padding: 2.5rem;
  display: flex;
  justify-content: center;
  gap: 8rem;
  align-items: center;
  position: relative;
  height: 100vh;
}

#get-in-touch .get-in-touch-square {
  position: absolute;
  left: 0;
  top: 0;
  height: 250px;
  width: 250px;
  background-color: var(--primary-color);
}

#get-in-touch div {
  max-width: 400px;
}

#get-in-touch h1 {
  font-size: 2rem;
  padding-bottom: 3rem;
  text-align: center;
}

#get-in-touch p {
  text-align: left;
  line-height: 150%;
}

#get-in-touch svg {
  height: 1.5rem;
}

#get-in-touch .contact-social-networks {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0rem;
  line-height: 1.2rem;
  font-size: 0.8rem;
}

#get-in-touch .contact-social-networks a {
  text-decoration: none;
}

#get-in-touch .social-networks {
  width: 200px;
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
}

#get-in-touch .form-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 400px;
}

#get-in-touch .form-wrapper input,
textarea {
  background-color: transparent;
  outline: none;
  border: none;
  border: 1px solid #fff;
  width: 100%;
  padding: 0.5rem;
}

#get-in-touch .form-wrapper input {
  height: 50px;
}

#get-in-touch .form-wrapper button {
  background-color: transparent;
  outline: none;
  border: none;
  border: 1px solid #fff;
  padding: 0.5rem;
  height: 50px;
}

#get-in-touch .form-wrapper button:hover {
  background-color: #fff;
  color: #000;
  transition: all 0.5s ease;
}

footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 4rem;
}

footer p {
  font-size: 0.9rem;
}

footer .mobile-footer {
  display: none;
}

.whatsapp_widget_chat_wrapper_inner > div:nth-child(3) {
  padding-top: 0.5rem !important;
}
