:root {
  --primary-color: hsla(221, 83%, 53%, 1);
  --accent-color: rgb(96 165 250);
}

@font-face {
  font-family: "Sora-Light";
  src: url("../assets/fonts/Sora-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Sora-ExtraLight";
  src: url("../assets/fonts/Sora-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: "Sora-Bold";
  src: url("../assets/fonts/Sora-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Sora-ExtraBold";
  src: url("../assets/fonts/Sora-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "Sora-Regular";
  src: url("../assets/fonts/Sora-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Sora-SemiBold";
  src: url("../assets/fonts/Sora-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Sora-Thin";
  src: url("../assets/fonts/Sora-Thin.ttf") format("truetype");
}

::-webkit-scrollbar {
  width: 0.2rem;
  height: 0.2rem;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px var(--border-color);
}

::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
}

button:hover {
  cursor: pointer;
}

._2qp0Z svg > path {
  fill: green !important;
}
