nav {
  position: fixed;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 2rem;
  top: 0;
  background-color: #0e0a31;
  z-index: 10;
}

nav img {
  width: 120px;
}

nav ul {
  display: flex;
  gap: 1rem;
}

nav ul li {
  display: flex;
}

nav ul li a {
  text-decoration: none;
}

nav button {
  background-color: var(--primary-color);
  height: 40px;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  border: none;
  border-radius: 10px;
  padding-inline: 1rem;
}

/* Service card */
.service-card {
  min-height: 200px;
  min-width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  text-align: center;
  padding: 1rem;
  padding-top: 2rem;
  scroll-snap-align: center;
  border-radius: 5px;
  justify-content: space-between;
  border: 1px solid #a9a9a9;
}

.service-card p {
  line-height: 120%;
  color: #fff;
}

.service-card button {
  background-color: var(--primary-color);
  border: none;
  padding: 10px 20px;
  outline: none;
}

.service-card button:hover {
  background-color: #fff;
  color: #000;
  transition: all 0.5s ease;
}
